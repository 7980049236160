//alert('.!.!.!.');
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
//* import the Swiper styles
import 'swiper/css';


// // import styles bundle
import 'swiper/css/bundle';


function initSwiper() {
    const swiper = new Swiper('.swiper', {
        // Optional parameters
        //direction: 'vertical',
        loop: true,
        autoHeight: true,
        lazy: true,
        touchRatio: .8,
        //allowTouchMove:false,
        lazyPreloadPrevNext: 2,
        //cssMode: true,
        slidesPerView: "auto",
        //slidesPerView: 1.3,
        centeredSlides: true,
        spaceBetween: 0,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true
        },
    });
}

document.addEventListener("DOMContentLoaded", function () {
    let read_more = document.querySelectorAll('.read-more-link');
    for (var i = 0; i < read_more.length; i++){
        console.log(read_more[i]);
        read_more[i].addEventListener('click', function (e) {
            var item = e.currentTarget;
            var cloased_box = item.parentElement.nextElementSibling;
            if (cloased_box.classList.contains('open')) {
                cloased_box.classList.remove('open');    
            } else {
                cloased_box.classList.add('open');    
            }
        })
        

    }
});

// Function to preload images within a specific element
function preloadImagesWithinElement(containerEle) {
    //var container = document.querySelector(containerEle);
    console.log(containerEle);
    var imgElements = containerEle.querySelectorAll('img');

    imgElements.forEach(function (img) {
        // Retrieve the image URL from the src attribute
        var imgUrl = img.src;

        // Check if the image is already loaded
        if (!img.complete) {
            // If not loaded, create a new Image object to preload the image
            var preloadImg = new Image();
            preloadImg.onload = function () {
                // Image is preloaded, you can perform additional actions if needed
                console.log('Image preloaded:', imgUrl);
            };
            preloadImg.onerror = function () {
                // Handle error when the image fails to load
                console.error('Error loading image:', imgUrl);
            };
            preloadImg.src = imgUrl;
        } else {
            // Image is already loaded
            console.log('Image already loaded:', imgUrl);
        }
    });

    
}

// Preload images within the specified container and check if they are preloaded
const checkExists = document.querySelectorAll('.swiper');
if (checkExists) {
    
    for (let i = 0; i < checkExists.length; i++) {
        preloadImagesWithinElement(checkExists[i]);
    }
    console.log('initSwiper');
    var stSpwr = setTimeout(function () {
        initSwiper();
    },1000);
}



/* 
Live Search on page 
Todo: Highllight searched text
function highlightSearchResuult(string){}
*/

document.addEventListener('DOMContentLoaded', function () {
    
    function liveSearch() {
        
        // Locate the card elements
        let cards = document.querySelectorAll('.item')
        let caps = document.querySelectorAll('.first-letter-caps')
        // Locate the search input
        let search_query = document.getElementById("searchbox").value;
        if (search_query != '') {
            // Loop through the cards
            for (var j = 0; j < caps.length; j++) {
                caps[j].classList.add("is-hidden");
            }
            for (var i = 0; i < cards.length; i++) {
                // If the text is within the card...
                var c = cards[i].querySelector('.artist-link');
                console.log(c);
                if (cards[i].innerText.toLowerCase().includes('<span class=""heighlight">')) {
                    console.log(cards[i]);
                }
                if (cards[i].innerText.toLowerCase()
                    // ...and the text matches the search query...
                    .includes(search_query.toLowerCase())) {
                    // ...remove the `.is-hidden` class.
                    cards[i].classList.remove("is-hidden");
                    let dataAttr = cards[i].getAttribute('data-alpha');
                    console.log(dataAttr);
                    let aa = document.querySelector(".alpha-" + dataAttr);
                    aa.classList.remove('is-hidden');
                    // highlight(search_query, cards[i]);
                    
                    
                } else {
                    // Otherwise, add the class.
                    cards[i].classList.add("is-hidden");
                }
            }
        } else {
            for (var i = 0; i < cards.length; i++) {
                cards[i].classList.remove('is-hidden');
            }
            for (var j = 0; j < caps.length; j++) {
                caps[j].classList.remove("is-hidden");
            }
        }
    }

    function highlight(needle,hay) {
        // chcek if hay is object
        var foundBlock = hay.querySelector('a').innerHTML.toLowerCase();
        //console.log(foundBlock);
        var index = foundBlock.indexOf(needle);
        //console.log(needle);
        if (index >= 0) {
            var substrFoundBlock = '<span class="highlight">' + foundBlock.substring(index, index + needle.length) + '</span>';
            var newText = foundBlock.replace(needle, substrFoundBlock);
            console.log(newText);
            
            hay.querySelector('a').innerHTML = newText;
            

        } else {
            return false;
        }
    }
    

    // A little delay
    let typingTimer;
    let typeInterval = 350; // Half a second
    let searchInput = document.getElementById('searchbox'); 
    if (searchInput) {

        searchInput.addEventListener('input', () => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(liveSearch, typeInterval);
        });
    }
});

// $('a.read-more-link').click(function () {
//     var this_ = $(this);
//     var parent_ = $(this).parent();
//     if (this_.hasClass('open')) {
//         this_.removeClass('open');
//         $(parent_).next('.read-more-action').css({ display: 'none' });
//     } else {
//         this_.addClass('open');
//         $(parent_).next('.read-more-action').css({ display: 'block' });
//     }
// });